<template>
  <div class="border border-romanSilver rounded p-3 flex flex-col gap-4">
    <div class="w-80 flex justify-between items-center">
      <p class="font-bold text-lg text-darkPurple ">
        <span class="text-romanSilver "></span> Approver
        {{ sequence }}
      </p>
      <button
        type="button"
        v-if="editable"
        @click="removeApprover"
        class=" w-5 -mt-2  "
      >
        <icon
          icon-name="close"
          class-name="text-darkPurple cursor-pointer"
          size="xsm"
        />
      </button>
    </div>
    <div class=" mt-0 flex">
      <img
        v-if="photo !== '' && photo !== null"
        class="mr-2 inline-block float-left rounded"
        alt="photo"
        style="width: 40px; height: 40px"
        :src="photo"
      />
      <div
        style="height: 40px; width: 40px; border-radius: 5px;"
        class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
        v-else
      >
        {{ $getInitials(`${name}`) }}
      </div>
      <div class="w-auto flex flex-col break-words ">
        <p class="text-sm font-semibold text-darkPurple">
          {{ name }}
        </p>
        <p class="text-sm font-semibold text-romanSilver">
          {{ designation }}
        </p>
      </div>
    </div>
    <div
      v-if="editable"
      class="w-80 flex justify-end items-center border-t border-romanSilver pt-3"
    >
      <Button
        type="button"
        @click="editApprover"
        class="text-white flex w-20"
        :background="appearance.buttonColor || defaultBTNColor"
      >
        Change
      </Button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Button from "@/components/Button";

export default {
  components: {
    Button
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: null
    },
    level: {
      type: String,
      default: null
    },
    designation: {
      type: String,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    sequence: {
      type: Number,
      default: null
    },
    photo: {
      type: String,
      default: null
    },
    functionId: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  methods: {
    editApprover() {
      this.$emit("edit-approver", {
        index: this.index,
        functionId: this.functionId
      });
    },
    removeApprover() {
      this.$emit("remove-approver", {
        index: this.index,
        functionId: this.functionId
      });
    }
  }
};
</script>
