<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <div class="w-full h-full p-1">
        <div class="flex">
          <back-button
            label="Back"
            @onClick="$router.back()"
            variant="secondary"
            class="mr-4"
          />
          <div
            class="mt-4"
            style="border-left: 1px solid #878e99; height: 1.5rem"
          />

          <div style="width: auto">
            <h1 class="text-xl text-left font-extrabold ml-4 mt-3 pr-3">
              Approval Workflow
            </h1>
          </div>
          <div class="w-7/12 mt-4">
            <Breadcrumb :items="breadcrumbs" />
          </div>
        </div>
        <div style="height: 100%" v-if="loadingData">
          <loader size="xxs" :loader-image="false" />
        </div>
        <div v-else>
          <div class="px-5">
            <p class="text-lg font-bold mb-2 border-b py-3">
              {{ pageType }} {{ $route.query.actionName }} Workflow
              <span
                class="float-right cursor-pointer text-flame text-base font-semibold"
              >
                <button v-if="pageType === 'Edit'">
                  <span @click="handleSubmit(save)">
                  <icon icon-name="save" size="xs" class=" inline-block" />
                  <span class="pl-2 pr-4">Save</span>
                  </span>
                  <span
                    @click="pageType = 'View'"
                    class="font-bold text-sm text-gray-500 cursor-pointer "
                  >
                    Cancel
                  </span>
                </button>
                <span v-if="pageType === 'View'" @click="activatePageEdit">
                  <icon icon-name="save" size="xs" class=" inline-block" />
                  <span class="pl-2">Edit</span>
                </span>
              </span>
            </p>
            <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
              <div class="col-span-4">
                <c-text
                  label="Workflow Name"
                  placeholder="--Enter--"
                  variant="w-full"
                  :rules="['required']"
                  v-model="approvalFlow.payload.workFlowName"
                  :disabled="disabled.name"
                />
              </div>
            </div>
          </div>
          <card class="p-5 my-4">
            <p class="text-lg font-bold mb-2">Location and Function Settings</p>
            <p class="text-sm">
              Select locations and initiator types to apply.
              The approval process for this event shall apply only to the
              locations and initiators selected. Choose 'Select All', to
               apply to All locations or All Job Roles.
              <!-- Simply select all for each, if the workflow is
          the same organisation, regardless of users’ location or function. -->
            </p>
            <p class="text-sm bg-ghostWhite p-2 my-3">
              *Individual selection applies more to situations where the approval
               flow differ by location or initiator.
                If your approval flow is the same across all locations and initiators,
                 you can choose Select All.
            </p>
            <div class="grid grid-cols-12 gap-7 mt-4 border-romanSilver">
              <div class="col-span-4">
              <label class="date-label">Location</label>
              <v-select
                :clearable="false"
                class="style-select"
                multiple
                :disabled="disabled.location || pageType === 'Edit'"
                :reduce="location => location.id"
                :options="locationData"
                :close-on-select="false"
                v-model="approvalFlow.payload.locations"
                @input="selectInitiatingLocation"
              >
                <template #option="{ name, id }">
                  <div class="flex">
                    <div class="hover:text-white">
                      <div class="flex justify-start items-center gap-2">
                        <c-checkbox :true-value="!approvalFlow.payload.locations.includes(id)" @focus.prevent :key="id"  />
                        <span class="font-bold text-base cursor-pointer">{{ name }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{name}">
                  {{ name }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon icon-name="caret-down" size="xsm" />
                  </span>
                </template>
              </v-select>

              </div>

              <div class="col-span-4">
                <c-select
                  placeholder="--Select--"
                  label="Initiator Type"
                  variant="w-full"
                  @onChange="selectInitiatorType($event)"
                  v-model="approvalFlow.payload.initiatorType"
                  :options="initiatorOptions"
                  :rules="['required']"
                  :disabled="disabled.location || pageType === 'Edit'"
                />
              </div>

              <div class="col-span-4" v-if="approvalFlow.payload.initiatorType === ''">
                <c-select
                  placeholder="--Select--"
                  label="Initiator"
                  variant="w-full"
                  :options="[]"
                  :disabled="true"
                />
              </div>

              <div class="col-span-4" v-if="approvalFlow.payload.initiatorType === 'function' && approvalFlow.payload.locations.length > 0">
              <label class="date-label">Initiator</label>
              <v-select
                :clearable="false"
                class="style-select"
                multiple
                :disabled="disabled.functions || pageType === 'Edit'"
                :reduce="functions => functions.id"
                :options="allFunctions"
                :close-on-select="false"
                v-model="approvalFlow.payload.functions"
                @input="selectInitiatingFunction"
              >
                <template #option="{ name, id }">
                  <div class="flex">
                    <div class="hover:text-white">
                      <div class="flex justify-start items-center gap-2">
                        <c-checkbox :true-value="!approvalFlow.payload.functions.includes(id)" @focus.prevent :key="id"  />
                        <span class="font-bold text-base cursor-pointer">{{ name }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{name}">
                  {{ name }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon icon-name="caret-down" size="xsm" />
                  </span>
                </template>
              </v-select>
              </div>


              <div class="col-span-4" v-if="approvalFlow.payload.initiatorType === 'role' && approvalFlow.payload.locations.length > 0">
              <label class="date-label">Initiator</label>
              <v-select
                :clearable="false"
                class="style-select"
                multiple
                :disabled="disabled.roles || pageType === 'Edit'"
                :reduce="roles => roles.id"
                :options="allRoles"
                :close-on-select="false"
                v-model="approvalFlow.payload.roles"
                @input="selectInitiatingJobRole"
              >
                <template #option="{ name, id }">
                  <div class="flex">
                    <div class="hover:text-white">
                      <div class="flex justify-start items-center gap-2">
                        <c-checkbox :true-value="!approvalFlow.payload.roles.includes(id)" @focus.prevent :key="id"  />
                        <span class="font-bold text-base cursor-pointer">{{ name }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{name}">
                  {{ name }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon icon-name="caret-down" size="xsm" />
                  </span>
                </template>
              </v-select>
              </div>
            </div>
        </card>
          <card
            v-if="
              approvalFlow.payload.functions.length > 0 || approvalFlow.payload.roles.length > 0
            "
            class="p-5"
          >
            <div>
              <p class="text-lg font-bold text-darkPurple font-semibold ">
                Approval Workflow
              </p>
              <p class="w-full mt-5">
                This is the final step. Select your prefered workflow and build your aprroval trail.
              </p>
            </div>

            <div class="my-5 w-full flex flex-col">
              <p class="w-full flex text-base font-semibold text-blueCrayola">
                Approval Type
              </p>

              <div class="my-2 flex flex-col w-full">
                <div class="w-full flex flex my-2 justify-start">
                      <RadioButton
                        space-between="mr-2 col-span-12 text-jet text-sm font-normal leading-5"
                        v-model="approvalFlow.payload.isDynamic"
                        default="designation"
                        row-gap="w-full"
                        @change="setIsDynamic($event)"
                        :options="[
                          { name: 'Manual', value: false, radioName: 'approvalType' },
                          { name: 'Dynamic', value: true, radioName: 'approvalType'},
                        ]"
                      />
                </div>
                <div class="flex w-full">
                  <div class="w-1/2 flex ml-2 border-l-2 border-dashed">
                    <div class="w-3/4 px-4 py-1">
                    Let me select the approvers directly, irrespective of my organization’s hierarchy.
                    </div>
                  </div>

                  <div class="w-1/2 flex ml-2 border-l-2 border-dashed">
                    <div class="w-3/4 px-4 py-1">
                    Use my organization hierarchy to build an approval structure.
                    </div>
                  </div>

                </div>
              </div>
            </div>






                <!---- Dynamic Approval-->
            <div v-if="approvalFlow.payload.isDynamic" class="my-7">
              <p class="text-base font-semibold text-blueCrayola my-3">
                Approval Flow
              </p>
              <p class="text-base">
                Cloudenly automates your approval flow based on your reporting hierarchy,
                from the lowest to the highest approver.
                You can uncheck any reporting line if you do not wish them to be part of this event approvers.
              </p>

              <div class="flex flex-col my-6">
                <p class="text-base font-semibold">Do all approvers in this function need to approve, or does it require a single approval to pass through?</p>
              <div class="w-full flex my-1">
                      <RadioButton
                        space-between="mr-2 col-span-12 text-jet text-sm font-normal leading-5"
                        v-model="approvalFlow.payload.completeApprovalFlow"
                        default="designation"
                        row-gap="w-full"
                        :options="[
                          { name: 'Yes, everyone needs to approve.', value: true, radioName: 'approvalDecide' },
                          { name: 'No, action needs only a single approval to be confirmed.', value: false,
                          radioName: 'approvalDecide'},
                        ]"
                      />
              </div>
              </div>


              <div class="w-full flex flex-col my-6">
                <p class="text-base">
                  Please uncheck any reporting line if you do not wish them to be part of this event approvers.
                </p>

              <div class="w-full flex flex-wrap my-2">
                <card class="p-2 mr-4 flex" v-for="(line, index) in approvalFlow.payload.reportingLine" :key="index">
                  <CCheckbox v-model="line.check" class="mt-1" />
                  <span class="mx-2">{{ $getOrdinal(line.position) }} Reporting Line </span>
                </card>
              </div>
              </div>


              <div class="my-6 flex flex-col w-full">
                <div class="w-full flex flex my-2 justify-start">
                      <RadioButton
                        space-between="mr-2 col-span-12 text-jet text-sm font-normal leading-5"
                        v-model="approvalFlow.payload.dynamicFunction"
                        default="designation"
                        row-gap="w-full"
                        @change="selectDynamicFunction($event)"
                        :options="[
                          { name: 'Single functional', value: 'single', radioName: 'dynamicFunction' },
                          { name: 'Cross-functional', value: 'cross', radioName: 'dynamicFunction' },
                        ]"
                      />
                </div>
                <div class="flex w-full">
                  <div class="w-1/2 flex ml-2 border-l-2 border-dashed">
                    <div class="w-3/4 px-4 py-1">
                    Select single function if this event requires approval only within a specific function.
                    </div>
                  </div>

                  <div class="w-1/2 flex ml-2 border-l-2 border-dashed">
                    <div class="w-3/4 px-4 py-1">
                    Select cross-functional if this event requires approval across one or more functions.
                    </div>
                  </div>

                </div>
              </div>

            </div>











            <!---- Manual Approval-->
            <div v-if="!approvalFlow.payload.isDynamic || approvalFlow.payload.dynamicFunction === 'cross'" class="my-7">
              <p class="text-base font-semibold">Select Approving Functions and approvers:</p>

              <p class="my-7 text-base" v-if="approvalFlow.payload.dynamicFunction === 'cross'">You have selected cross-functional approval above;
                define your preferred approval flow across other relevant approving functions.</p>

            <div v-for="(data, index) in functionalDomains" :key="index">
              <div>
                <div
                  class="grid grid-cols-12 gap-7"
                  style="margin-top: 1.125rem"
                >
                  <div class="col-span-4">
                    <p
                      v-if="index === 0 && approvalFlow.payload.dynamicFunction !== 'cross'"
                      class="text-darkPurple font-black mt-3 uppercase text-xs"
                    >
                      First Approving Function
                    </p>
                    <p
                      v-else
                      class="text-darkPurple font-black mt-3 uppercase text-xs"
                    >
                      Next Approving Function:
                    </p>
                    <c-select
                      v-if="
                        index === approvalFlow.payload.domains.length - 1 &&
                          index !== 0
                      "
                      placeholder="--Select--"
                      variant="w-full"
                      @input="selectFunction($event)"
                      :options="unUsedfunctionalDomains"
                      v-model="approvalFlow.payload.domains[index].functionId"
                      :disabled="disabled.approver"
                    />
                    <c-select
                      v-else
                      placeholder="--Select--"
                      variant="w-full"
                      @onChange="selectFunction"
                      :options="functionData"
                      :value="approvalFlow.payload.domains[index].functionId"
                      v-model="approvalFlow.payload.domains[index].functionId"
                      :disabled="disabled.approver"
                    />
                  </div>
                </div>
                <div class="w-full flex flex-col my-2">
                  <p class="text-base font-semibold">Do all approvers in this function need to approve, or does it require a single approval to pass through?</p>
                <div class="w-full flex flex my-2 justify-start">
                      <RadioButton
                        space-between="mr-2 col-span-12 text-jet text-sm font-normal leading-5"
                        v-model="approvalFlow.payload.domains[index].isApprovalForAll"
                        default="designation"
                        @change="setIsApprovalForAll($event,index)"
                        row-gap="w-full"
                        :options="[
                          { name: 'Yes, everyone needs to approve.', value: true, radioName: `isApproval${index}` },
                          { name: 'No, action needs only a single approval to be confirmed.', value: false,
                          radioName: `isApproval${index}`},
                        ]"
                      />
                </div>
                </div>
              </div>
              <div class="flex items-center mt-8 overflow-x-auto scroll">
                <div class="flex items-center mb-5 space-x-4">
                  <div v-if="data.approvers.length > 0" class="flex space-x-4">
                    <div
                      v-for="(approverData, approverIndex) in data.approvers"
                      :key="approverIndex"
                    >
                      <ApproverCard
                        v-if="approverData.edit === false"
                        :editable="!disabled.approver"
                        :name="approverData.name"
                        :designation="approverData.designation"
                        :function-id="approverData.functionId"
                        :level="approverData.level"
                        :index="approverIndex"
                        :sequence="approverData.sequence"
                        :photo="approverData.photo"
                        @remove-approver="removeApprover(index, approverIndex)"
                        @edit-approver="editApprover(index, approverIndex)"
                      />

                      <div
                        v-else
                        class="border border-romanSilver rounded p-3 flex flex-col gap-4"
                      >
                        <div class="w-80 flex justify-between items-center">
                          <p class="font-bold text-lg text-darkPurple">
                            <span class="text-romanSilver mr-3"></span> Edit
                            Approver {{ approverData.sequence }}
                          </p>
                          <span
                            @click="
                              approvalFlow.payload.domains[index].approvers[
                                approverIndex
                              ].edit = false
                            "
                            class="font-bold text-sm text-gray-500 cursor-pointer "
                          >
                            Cancel
                          </span>
                        </div>
                        <c-select
                          @onChange="handleSelectedApprover($event)"
                          placeholder="Select"
                          variant="w-full"
                          :options="functionUsers"
                          v-model="
                            approvalFlow.payload.domains[index].approvers[
                              approverIndex
                            ].id
                          "
                        />

                        <div
                          class="w-80 flex justify-between items-center border-t border-romanSilver pt-3"
                        >
                          <button
                            @click="updateApprover(index, approverIndex)"
                            type="button"
                            class="text-flame bg-white flex w-14"
                          >
                            update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="data.showNewApproverCard"
                    class=" border border-romanSilver rounded p-3 flex flex-col gap-4"
                  >
                    <div class="w-80 flex justify-between items-center">
                      <p class="font-bold text-lg text-darkPurple">
                        <span class="text-romanSilver mr-3"></span> New Approver
                      </p>
                      <span @click="removeNewApproverCard(data.functionId)">
                        <icon
                          icon-name="close"
                          class-name="text-darkPurple"
                          size="xsm"
                        />
                      </span>
                    </div>
                    <c-select
                      @onChange="handleSelectedApprover($event)"
                      placeholder="Select"
                      variant="w-full"
                      :options="functionUsers"
                    />

                    <div
                      class="
                    w-80
                    flex
                    justify-end
                    items-center
                    border-t border-romanSilver
                    pt-3
                  "
                    >
                      <Button
                        @click="addApprover"
                        type="button"
                        class="bg-dynamicBackBtn text-white flex w-20"
                      >
                        Add
                      </Button>
                    </div>
                  </div>

                  <template
                    v-if="
                      pageType !== 'View' &&
                        !data.showNewApproverCard &&
                        approvalFlow.payload.domains[index].functionId
                    "
                  >
                    <icon
                      icon-name="card_arrow"
                      class-name="text-romanSilver mx-3"
                      size="xl"
                    />
                    <button
                      @click="
                        addNewApproverCard(
                          approvalFlow.payload.domains[index].functionId
                        )
                      "
                      type="button"
                      class="text-flame bg-white flex w-14"
                    >
                      <div class="flex items-center">
                        <icon
                          icon-name="icon-plus"
                          class-name="text-romanSilver"
                          size="xsm"
                        />
                        <span
                          class="w-32"
                          v-if="index === 0 && data.approvers.length === 0"
                          v-tooltip.right="
                            `If you do not have Approver(s) in this function,
                         please proceed to add from another function`
                          "
                          >Add Approver</span
                        >
                        <span class="w-32" v-else>Add Approver</span>
                      </div>
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <div
              class="w-full"
              v-if="
                pageType !== 'View' && approvalFlow.payload.domains.length !== 0
              "
            >
              <button
                v-if="approvalFlow.payload.domains.length !== 0 && unUsedfunctionalDomains.length > 0"
                @click="addNewFunctionalDomain"
                type="button"
                class="text-flame bg-white flex "
              >
                <div class="flex w-full items-center">
                  <icon
                    icon-name="icon-plus"
                    class-name="text-romanSilver"
                    size="xsm"
                  />
                  <span class="w-full ml-2 text-sm">
                    Add a Functional Domain</span
                  >
                </div>
              </button>
            </div>

            <div v-if="!hasApprover" class="my-3 md:w-2/4">
              <div
                class="flex items-center bg-yellow-50 border-l-4 border-yellow-300 text-yellow-700 px-3 py-2 text-sm rounded relative "
                role="alert"
              >
                <svg
                  class="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                  />
                </svg>
                <p>You must add at least one approver.</p>
              </div>
            </div>
            </div>



          </card>

          <div v-if="pageType === 'Create'" class="flex px-5 my-4">
            <Button
              v-if="hasApprover || approvalFlow.payload.dynamicFunction === 'single'"
              class="bg-dynamicBackBtn text-white rounded mb-5"
              :disabled="disableBtn"
              @click="handleSubmit(save)"
            >
              Save
            </Button>
            <Button
              v-else
              :disabled="true"
              class="bg-gray-200 text-gray-400 rounded mb-5 cursor-not-allowed"
            >
              Save
            </Button>
            <button
              type="button"
              @click="$router.back()"
              class=" font-semibold rounded-md text-center radius buttonFocus "
              style="background: none; border: none; height: 2.75rem; width: 12rem;"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
  </ValidationObserver>
</template>

<script>
import VSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import { ValidationObserver } from "vee-validate";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CCheckbox from "@scelloo/cloudenly-ui/src/components/checkbox"
import Card from "@/components/Card";
import Button from "@/components/Button";
import RadioButton from "@/components/RadioButton";
import ApproverCard from "@/components/ApproverCard";

// Get Selected Function
// Add Approver's  To Selected Function
// Add Selected Functions To Domains
export default {
  name: "CreateApprovalWorkflow",
  components: {
    VSelect,
    Breadcrumb,
    CCheckbox,
    Card,
    BackButton,
    ValidationObserver,
    RadioButton,
    CText,
    CSelect,
    Button,
    // RadioButton,
    ApproverCard
  },
  data() {
    return {
      disableBtn: false,
      breadcrumbs: [
        {
          disabled: false,
          text: "Settings",
          href: "settings",
          id: "Settings"
        },
        {
          disabled: false,
          text: "Approval",
          id: "Approval"
        },
        {
          disabled: false,
          text: "Workflow",
          id: "Workflow"
        }
      ],
      loadingData: false,
      approvalType: 'false',
      functionData: [],
      actions: [
        { id: 1, name: "Job Level 1" },
        { id: 1, name: "Job Level 2" },
        { id: 1, name: "Job Level 3" },
        { id: 1, name: "Job Level 4" },
        { id: 1, name: "Job Level 5" },
        { id: 1, name: "Job Level 6" }
      ],
      initiatorOptions: [
        {
          id: 'function', name: 'Function'
        },
        {
          id: 'role', name: 'Job Role'
        }
      ],
      disabled: {
        name: false,
        functions: false,
        roles: false,
        location: false,
        approver: false
      },
      initiatingFunction: '',
      pageType: "Create",
      creatingApproval: false,
      allFunctions: [],
      allRoles: [],
      locationData: [],
      rolesData: [],
      selectedLocation: null,
      selectedFunction: null,
      selectedFunctionList: [],
      reportingLineList: [],
      functionUsers: [],
      unUsedfunctionalDomains: [],
      approver: {
        name: null,
        level: null,
        userId: null,
        designation: null,
        sequence: "1"
      },
      allLocations: [],
      reportingLine: [],
      approvalFlow: {
        payload: {
          appSlug: "humanar",
          functions: [],
          roles: [],
          initiatorType: '',
          applyToAllRole: false,
          applyToAllFunction: false,
          isDynamic: false,
          actionId: null,
          actionSlug: null,
          dynamicFunction: '',
          locationNames: [],
          locations: [],
          workFlowName: null,
          applyToAllLocation: false,
          completeApprovalFlow: true,
          reportingLine: [],
          functionNames: [],
          roleNames: [],
          domains: [],
        }
      },
      manual: [
        {
          name: "Manual",
          radioName: "type",
          value: false
        }
      ],
      dynamic: [
        {
          name: "Dynamic",
          radioName: "type",
          value: true
        }
      ],
      flow: [
        {
          name: "Yes, everyone needs to approve.",
          radioName: "flow",
          value: "true"
        },
        {
          name:
            "No, action/form needs only a single approval to be confirmed. any of the approvers can approve",
          radioName: "flow",
          value: "false"
        }
      ],
      initiatorType: ''
    };
  },

  methods: {
    selectInitiatorType(data) {
      this.initiatorType = data;
      if (data === 'function') {
        this.approvalFlow.payload.initiatorType = 'function'
      }
      else {
        this.approvalFlow.payload.initiatorType = 'role'
      }
    },

    async activatePageEdit() {
      try {
        await this.$handlePrivilege(
          "approvalWorkflowHR",
          "editWorkflowSub-category"
        );
        this.pageType = 'Edit';
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    init() {
      this.getLocations();
      this.getFunctions();
      this.getDesignations();
      this.approvalFlow.payload.actionId = this.$route.params.actionId;
      this.approvalFlow.payload.actionSlug = this.$route.params.actionSlug;
    },

    getReportingLineFunctions(payload) {
      const functions = { functions: payload };
      this.$_getReportingLineFunction(functions).then((result) => {
        for (let i = 1; i <= result.data.topHierarchyCount; i++) {
          this.approvalFlow.payload.reportingLine.push({
            position: i,
            check: true
          });
        }
      })
    },

    getReportingLineRoles(payload) {
      const roles = { designations: payload };
      this.$_getReportingLineRoles(roles).then((result) => {
        for (let i = 1; i <= result.data.topHierarchyCount; i++) {
          this.approvalFlow.payload.reportingLine.push({
            position: i,
            check: true
          });
        }
      })
    },

    retrieveWorkflow() {
      this.loadingData = true;
      this.$_retrieveSingleWorkflow(this.$route.params.workflowId).then(
        async response => {
          const { domains, ...payload } = response.data;

          let lastDomain = {};
          const functionalDomains = await Promise.all(
            domains.map((domain, index) => {
              const { approvers, ...domainData } = domain;
              // Restructure approvers data
              const approversData = approvers.map(approver => {
                const designation = approver.userDesignation
                  ? approver.userDesignation.name
                  : null;
                const level = approver.userLevel
                  ? approver.userLevel.name
                  : null;
                return {
                  name: `${approver.fname} ${approver.lname}`,
                  id: approver.userId,
                  userId: approver.userId,
                  aaproverId: approver.id,
                  edit: false,
                  photo: approver.photo,
                  functionId: domain.functionId,
                  level,
                  designation
                };
              });

              const funtionalDomain = {
                id: domainData.functionId,
                domainId: domainData.id,
                name: domain.functionName,
                functionId: domainData.functionId,
                functionName: domain.functionName,
                isApprovalForAll: domain.isApprovalForAll,
                showNewApproverCard: false
              };
              // set last domain
              if (index === domains.length - 1) {
                lastDomain = funtionalDomain;
              }
              // functional domain
              return { ...funtionalDomain, approvers: approversData };
            })
          );
          this.pageType = "View";

          this.approvalFlow.payload = {
            ...payload,
            domains: functionalDomains
          };

          if (!this.approvalFlow.payload.locations) {
            this.approvalFlow.payload.locationNames = payload.locationName
          }

          if (!this.approvalFlow.payload.functions) {
            this.approvalFlow.payload.functionNames = payload.functionName;
          }

          this.unUsedfunctionalDomains = [
            ...this.findUnUsedfunctions(),
            lastDomain
          ];
        }
      );
      this.loadingData = false;
    },

    save() {
      this.disableBtn = true;
      // Restructure request payload to suit backend expectation
      // and also prevent mutation of data used in the template
      // in order to prevent errors

      const { domains, ...payload } = this.approvalFlow.payload;
      const workflow = {
        userInfo: {
          orgId: this.$OrgData.id,
          creatorId: this.$AuthUser.id,
          outletId: this.$OrgData.defaultLocation
        },
        payload
      };
      const functionalDomains = this.functionalDomains.filter(
        domain => domain.approvers.length !== 0
      );
      workflow.payload.domains = functionalDomains.map(domain => {
        const approvers = domain.approvers.map(approver => ({
          userId: approver.userId,
          sequence: approver.sequence
        }));
        return {
          functionName: domain.functionName,
          functionId: domain.functionId,
          isApprovalForAll: domain.isApprovalForAll,
          approvers
        };
      });

      // Save request to backend
      if (this.pageType === "Create") {
        this.$_saveApproval(workflow)
          .then(() => {
            this.creatingApproval = true;

            this.$toasted.success("Workflow created successfully.", {
              duration: 3000
            });
            this.disableBtn = false;
            return this.$router.push({
              name: "ApprovalWorkFlow",
              params: {
                actionId: this.$route.params.actionId,
                actionSlug: this.$route.params.actionSlug
              }
            });
          })
          .catch(() => {
            this.creatingApproval = false;
            this.disableBtn = false;
            this.$toasted.error("Could not create workflow.", {
              duration: 3000
            });
          });
      }

      // Updat workflow
      if (this.pageType === "Edit") {
        this.$_updateApproval({
          workflowId: workflow.payload.groupId,
          payload: { payload: workflow.payload }
        })
          .then(() => {
            this.creatingApproval = true;
            this.loadingData = true;
            this.pageType = "View";
            this.loadingData = false;
            this.disableBtn = false;
            this.$toasted.success("Workflow updated successfully.", {
              duration: 3000
            });
          })
          .catch(error => {
            this.$toasted.error("Could not update workflow.", {
              duration: 3000
            });
            this.creatingApproval = false;
            this.disableBtn = false;
            this.loadingData = false;
            throw new Error(error);
          });
      }
    },


    // get all locations
    getLocations() {
      this.$_getHumanarLocations().then(result => {
        this.allLocations = [{ id: 'All Location', name: 'All Locations' }, ...result.data.outlets];
        this.locationData = [...this.allLocations]
      });
    },
    // get all functions
    getFunctions() {
      this.$_getFunctionKpis().then(result => {
        this.allFunctions = [{ id: 'All Function', name: 'All Functions' }, ...result.data.functions];
        this.functionData = result.data.functions
        this.loadingData = false;
      });
    },

    getDesignations() {
      this.$_getDesignations("").then(response => {
        this.allRoles = [{ id: 'All Role', name: 'All Roles' }, ...response.data.designations];
        this.rolesData = response.data.designations;
      });

    },

    selectFunction(functionId) {
      //  Check If Functional Domain Exists In Domains
      const selectedDomain = this.approvalFlow.payload.domains.find(
        domain => domain.functionId === functionId
      );
      const selectedFunction = this.findFunctionById(functionId);

      if (selectedDomain) {
        // If Functional Domain Exists In Domains, Assign Function To Selected Function
        const domainIndex = this.approvalFlow.payload.domains.findIndex(
          domain => domain.functionId === functionId
        );

        this.approvalFlow.payload.domains[domainIndex] = selectedFunction;
      } else {
        // If Functionanl Domain Does Not Exist Create A New Domain

        // remove previous selected domain
        this.approvalFlow.payload.domains = this.approvalFlow.payload.domains.filter(
          (domain, index) =>
            index !== this.approvalFlow.payload.domains.length - 1
        );
        this.approvalFlow.payload.domains.push(selectedFunction);
      }
    },

    selectInitiatingFunction(functionData) {
      //  Reset selected functional domains
      this.approvalFlow.payload.domains = [];

      this.approvalFlow.payload.functionNames = [];

      if (functionData.includes('All Function')) {
        this.approvalFlow.payload.functions = ['All Function'];
        this.approvalFlow.payload.functionNames = ['All Functions'];
        this.approvalFlow.payload.applyToAllFunction = true;
        this.approvalFlow.payload.domains.push(this.findFunctionById());
        if (this.findUnUsedfunctions.length > 0) {
          this.unUsedfunctionalDomains = this.findUnUsedfunctions();
        }
        else {
          this.unUsedfunctionalDomains = this.functionData
        }
      }
      else if (functionData.length < this.allFunctions.length) {
        this.approvalFlow.payload.functions = functionData;
        this.approvalFlow.payload.applyToAllFunction = false;
        functionData.forEach((data) => {
          this.approvalFlow.payload.functionNames.push(this.functionData.filter((functions) => functions.id === data).map((x) =>  x.name)[0]);
        });
        this.approvalFlow.payload.domains.push(this.findFunctionById());
        if (this.findUnUsedfunctions.length > 0) {
          this.unUsedfunctionalDomains = this.findUnUsedfunctions();
        }
        else {
          this.unUsedfunctionalDomains = this.functionData
        }

        if (this.approvalFlow.payload.functions.length === 0) {
          this.approvalFlow.payload.isDynamic = false;
        }
        else {
          this.setIsDynamic(this.approvalFlow.payload.isDynamic);
        }
      }
      else {
        this.approvalFlow.payload.applyToAllFunction = false;
      }
    },


    selectInitiatingJobRole(roleData) {
      //  Reset selected functional domains
      this.approvalFlow.payload.domains = [];

      this.approvalFlow.payload.roleNames = [];

      if (roleData.includes('All Role')) {
        this.approvalFlow.payload.roles = ['All Role'];
        this.approvalFlow.payload.roleNames = ['All Roles'];
        this.approvalFlow.payload.applyToAllRole = true;
        this.approvalFlow.payload.domains.push(this.findFunctionById());
        if (this.findUnUsedfunctions.length > 0) {
          this.unUsedfunctionalDomains = this.findUnUsedfunctions();
        }
        else {
          this.unUsedfunctionalDomains = this.functionData
        }
      }
      else if (roleData.length < this.allRoles.length) {
        this.approvalFlow.payload.roles = roleData;
        this.approvalFlow.payload.applyToAllRole = false;
        roleData.forEach((data) => {
          this.approvalFlow.payload.roleNames.push(this.rolesData.filter((roles) => roles.id === data).map((x) =>  x.name)[0]);
        });
        this.approvalFlow.payload.domains.push(this.findFunctionById());
        if (this.findUnUsedfunctions.length > 0) {
          this.unUsedfunctionalDomains = this.findUnUsedfunctions();
        }
        else {
          this.unUsedfunctionalDomains = this.functionData
        }

        if (this.approvalFlow.payload.roles.length === 0) {
          this.approvalFlow.payload.isDynamic = false;
        }
        else {
          this.setIsDynamic(this.approvalFlow.payload.isDynamic);
        }
      }
      else {
        this.approvalFlow.payload.applyToAllRole = false;
      }
    },


    selectInitiatingLocation(locationData) {
      this.approvalFlow.payload.locationNames = [];

      if (locationData.includes('All Location')) {
        this.approvalFlow.payload.locations = ['All Location'];
        this.approvalFlow.payload.locationNames = ['All Locations'];
        this.approvalFlow.payload.applyToAllLocation = true;
      } else if (locationData.length < this.locationData.length) {
        this.locationData = this.allLocations;
        this.approvalFlow.payload.locations = locationData;
        this.locationData.forEach((data) => {
          if (locationData.includes(data.id)) {
            this.approvalFlow.payload.locationNames.push(data.name)
          }
        });
        this.approvalFlow.payload.applyToAllLocation = false;
      } else {
        this.locationData = this.allLocations;
        this.approvalFlow.payload.applyToAllLocation = false;
      }
    },

    findFunctionById(functionId) {
      const functionData = this.functionData.find(
        value => value.id === functionId
      );
      if (functionData) {
        return {
          id: functionData.id,
          name: functionData.name,
          functionId: functionData.id,
          functionName: functionData.name,
          isApprovalForAll: true,
          showNewApproverCard: false,
          approvers: []
        };
      }
      this.functionUsers = [];
      return {
        id: null,
        name: null,
        functionId: null,
        functionName: null,
        isApprovalForAll: true,
        showNewApproverCard: false,
        approvers: []
      };
    },

    findFunctionIndex(functionId) {
      return this.approvalFlow.payload.domains.findIndex(
        domain => domain.functionId === functionId
      );
    },

    findUnUsedfunctions() {
      // get ids of used functional domains
      const usedFunctions = this.approvalFlow.payload.domains.filter(
        domain => domain.functionId !== null
      );
      const lastUsedFunction = usedFunctions[usedFunctions.length - 1];
      // return functions that are not allocated
      return this.functionData.filter(
        domain => domain.id !== lastUsedFunction.functionId && domain.id !== "All Function"
      );
    },

    // get function users
    async getUsersByFunctionId(functionId) {
      const approversId = [];

      const functionData = this.selectedFunctionList.find(
        data => data.functionId === functionId
      );
      if (functionData) {
       const domains = this.approvalFlow.payload.domains.filter(
        domain => domain.functionId === functionId
        );

        domains.forEach((domain) => {
          domain.approvers.forEach((approver) => {
            approversId.push(approver.userId)
          })
        })

        this.functionUsers = functionData.users.filter((data) => (!approversId.includes(data.userId)));
      } else {
        await this.getFunctionUsersFromServer(functionId);
      }
      return true;
    },

    // get function  users from server
    async getFunctionUsersFromServer(functionId) {
      await this.$_getFunctionUsers({ functionId }).then(result => {
        const usersData = result.data.users;
        const users = usersData.map(item => {
          let level = "";
          let designation = "";
          if (item.userLevel) {
            level = item.userLevel.name;
          }
          if (item.userDesignation) {
            designation = item.userDesignation.name;
          }
          return {
            name: `${item.fname} ${item.lname}`,
            id: item.id,
            userId: item.id,
            photo: item.photo,
            functionId: item.functionId,
            level,
            designation,
            edit: false
          };
        });
        this.selectedFunctionList.push({ functionId, users });

        this.functionUsers = users;
      });
    },
    handleSelectedApprover(userId) {
      const functionData = this.selectedFunctionList.find(data =>
        data.users.find(user => user.userId === userId)
      );
      const user = functionData.users.find(data => data.userId === userId);
      if (user) {
        this.approver = { ...user, edit: false };
      }
    },
    addApprover() {
       this.initiatingFunction = "";
      // Update the value of the selected domain in domains
      const domainIndex = this.approvalFlow.payload.domains.findIndex(
        domain => domain.functionId === this.approver.functionId
      );
      if (domainIndex !== -1) {
        this.approvalFlow.payload.domains[
          domainIndex
        ].showNewApproverCard = false;
        this.approvalFlow.payload.domains[domainIndex].approvers.push(
          this.approver
        );
        // this.approver = {};
        this.removeNewApproverCard(this.approver.functionId);
      }
      return false;
    },
    removeApprover(domainIndex, approverIndex) {
      this.approvalFlow.payload.domains[
        domainIndex
      ].approvers = this.approvalFlow.payload.domains[
        domainIndex
      ].approvers.filter((data, index) => index !== approverIndex);
    },
    async editApprover(domainIndex, approverIndex) {
      // get functional domains
      const { domains } = this.approvalFlow.payload;

      // disable edit state for all appprovers
      domains.forEach((domain, domIndex) => {
        domain.approvers.forEach((approver, index) => {
          this.approvalFlow.payload.domains[domIndex].approvers[
            index
          ].edit = false;
        });
      });

      // get users for edited function
      await this.getUsersByFunctionId(domains[domainIndex].functionId);

      // Enable edit state for selected approver
      this.approvalFlow.payload.domains[domainIndex].approvers[
        approverIndex
      ].edit = true;
    },
    updateApprover(domainIndex, approverIndex) {
      // Change approver
      const domains = [];
      this.approvalFlow.payload.domains = this.approvalFlow.payload.domains.forEach(
        (domain, index) => {
          const approvers = domain.approvers.map((approver, apprIndex) => {
            if (domainIndex === index && apprIndex === approverIndex) {
              return this.approver;
            }
            return approver;
          });
          domains.push({ ...domain, approvers });
        }
      );

      this.approvalFlow.payload.domains = domains;
      this.approver = {};
    },

    addNewApproverCard(functionId) {
      const domainIndex = this.findFunctionIndex(functionId);
      if (domainIndex !== -1) {
        this.getUsersByFunctionId(functionId);
        // Remove new approver card from other funtional domains
        this.approvalFlow.payload.domains = this.approvalFlow.payload.domains.map(
          domain => {
            const functionalDomain = domain;
            if (functionId === domain.functionId) {
              functionalDomain.showNewApproverCard = true;
            } else {
              functionalDomain.showNewApproverCard = false;
            }
            return functionalDomain;
          }
        );
      }
    },
    removeNewApproverCard(functionId) {
      const domainIndex = this.findFunctionIndex(functionId);
      if (domainIndex !== -1) {
        this.approvalFlow.payload.domains[
          domainIndex
        ].showNewApproverCard = false;
      }
    },
    addNewFunctionalDomain() {
      this.unUsedfunctionalDomains = this.findUnUsedfunctions();
      this.approvalFlow.payload.domains.push(this.findFunctionById());
    },

    getDomainApprovalForAllOptions(domainIndex) {
      // get new radionName  for each functional domain
      return [
        {
          name: "Yes, everyone needs to approve.",
          radioName: `domain${domainIndex}`,
          value: "true"
        },
        {
          name:
            "No, action/form needs only a single approval to be confirmed. any of the approvers can approve",
          radioName: `domain${domainIndex}`,
          value: "false"
        }
      ];
    },
    setApprovalType(value) {
      this.approvalFlow.payload.isDynamic = value;
    },

    setIsDynamic(value) {
      const boolVal = (value === "true");
      this.approvalFlow.payload.isDynamic = boolVal;
      this.approvalFlow.payload.reportingLine = [];

      if (value === 'true') {
        if (this.approvalFlow.payload.initiatorType === 'function') {
          if (this.approvalFlow.payload.applyToAllFunction === true) {
            const arrPayload = this.functionData.map(data => data.id);
            this.getReportingLineFunctions(arrPayload);
          }
          else {
            this.getReportingLineFunctions(this.approvalFlow.payload.functions);
          }
        }
        else if (this.approvalFlow.payload.applyToAllRole === true) {
            const arrPayload = this.rolesData.map(data => data.id);
            this.getReportingLineRoles(arrPayload);
          }
          else {
            this.getReportingLineRoles(this.approvalFlow.payload.roles);
          }
      }
    },

    setIsApprovalForAll(value, index) {
      const boolVal = (value === "true");
      this.approvalFlow.payload.domains[index].isApprovalForAll = boolVal
    },

    selectDynamicFunction(value) {
      this.approvalFlow.payload.domains = [];
      if (value === 'cross') {
        this.approvalFlow.payload.domains.push(this.findFunctionById());
      }
      else {
        this.approvalFlow.payload.domains = [];
      }
    }
  },
  filters: {
    ordinal(number) {
      const ordinalRules = new Intl.PluralRules("en", {
        type: "ordinal"
      });
      const suffixes = {
        one: "st",
        two: "nd",
        few: "rd",
        other: "th"
      };
      const suffix = suffixes[ordinalRules.select(number)];
      return number + suffix;
    }
  },
  created() {
    this.init();
    if (this.$route.params.workflowId) {
      this.retrieveWorkflow();
    }
  },
  computed: {
    functionalDomains() {
      let sequence = 0;
      return this.approvalFlow.payload.domains.map(domain => {
        const approvers = domain.approvers.map(approver => {
          sequence += 1;
          return { ...approver, sequence };
        });
        return { ...domain, approvers };
      });
    },
    canAddFunctionalDomain() {
      const { domains } = this.approvalFlow.payload;
      const lastDomainIndex = domains.length - 1;
      const lastDomainApproversCount =
        domains[lastDomainIndex].approvers.length;
      if (
        (lastDomainIndex === 0 || lastDomainApproversCount !== 0) &&
        this.unUsedfunctionalDomains.length > 0
      ) {
        return true;
      }
      return false;
    },
    hasApprover() {
      return this.approvalFlow.payload.domains.some(
        domain => domain.approvers.length > 0
      );
    }
  },
  watch: {
    pageType(type) {
      if (type === "Create") {
        this.disabled = {
          name: false,
          functions: false,
          location: false,
          approver: false
        };
      }

      if (type === "Edit") {
        this.disabled = {
          name: false,
          functions: true,
          location: true,
          roles: true,
          approver: false
        };
      }

      if (type === "View") {
        this.disabled = {
          name: true,
          functions: true,
          location: true,
          roles: true,
          approver: true
        };
      }
    }
  }
};
</script>

<style scoped>
.scroll::-webkit-scrollbar {
  width: 0;
  margin-top: 1rem;
}

.scroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background: #eeeeee;
}

.scroll::-webkit-scrollbar-thumb {
  height: 8px;
  background-color: #878e99;
  margin-top: 1rem;
}
</style>
